/* eslint-disable react-hooks/exhaustive-deps */
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import {
  IAllQuestionItem,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import { useEffect, useState } from 'react';
import { useStarredEntities } from '@backstage/plugin-catalog-react';

export const useGetQuestions = (
  contentPerPage: number,
  tagName: string | undefined,
  filters: any,
  shouldcall?: number,
) => {
  const notificationApi: NotificationApi = useApi(notificationApiRef);
  const informationExchangeApi = useApi(informationExchangeApiRef);

  const [questionsData, setQuestionsData] =
    useState<IAllQuestionItem['data']>();
  const [isLoading, setIsLoading] = useState(true);
  const { starredEntities } = useStarredEntities();

  const createRequestParams = () => {
    const { filterSelected, selectedBtn, searchTextValue, currentIndex } =
      filters;
    const params = {
      page: currentIndex + 1,
      pagesize: contentPerPage,
      sortby: selectedBtn,
      ...(filterSelected && { filterby: filterSelected }),
      ...(tagName && { tag: `${tagName}` }),
      ...(searchTextValue &&
        filterSelected === 'all' && { search: searchTextValue }),
    };
    return params;
  };

  const getAllQuestionData = async (params: any) => {
    setIsLoading(true);
    if (informationExchangeApi) {
      try {
        const data: any = await informationExchangeApi.getAllQuestion(params);
        setQuestionsData(data);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      }
    }
  };

  const getAllBookmarkedQuestionData = (param: any) => {
    setIsLoading(true);
    let ids: any = [];

    if (starredEntities !== undefined && starredEntities?.size > 0) {
      ids = [...starredEntities]
        ?.filter(i => i?.indexOf('question:') === 0)
        .map(j => `${j}`.substring(j?.indexOf('/') + 1));
    }
    informationExchangeApi
      .getAllBookmarkedQuestions({ ...param, idList: ids })
      .then((data: any) => {
        setQuestionsData(data);
        setIsLoading(false);
      })
      .catch(() => {
        notificationApi.sendNotification({
          message: `Failed to load Data`,
          disapperAfterMs: 2500,
          severity: 'error',
        });
      });
  };

  useEffect(() => {
    const params = createRequestParams();
    if (filters.filterSelected === 'bookmarked') {
      getAllBookmarkedQuestionData(params);
    } else {
      getAllQuestionData(params);
    }
  }, [tagName, filters, shouldcall]);

  return { questionsData, isLoading } as const;
};
