import React, { useEffect, useState } from 'react';
import 'react-quill/dist/quill.snow.css';
import './codeHighlight';
import ReactQuill, { Quill } from 'react-quill';
import { ImageDrop } from 'quill-image-drop-module';
// import { IMAGE_MAX_SIZE } from '../../utils/constant';
import { Theme, createStyles, makeStyles } from '@material-ui/core';

Quill.register('modules/imageDrop', ImageDrop);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    quill: {
      marginTop: '15px',
      border: '1px solid #f2f2f2',
      borderRadius: '8px',
      '& .ql-container': {
        borderBottomLeftRadius: '0.5em',
        borderBottomRightRadius: '0.5em',
      },

      /* Snow Theme */
      '& .ql-snow.ql-toolbar': {
        display: 'block',
        borderTopLeftRadius: '0.5em',
        borderTopRightRadius: '0.5em',
      },

      '& button:hover, & .ql-snow.ql-toolbar button.ql-active': {
        '& .ql-picker': {
          color: `${theme.palette.primary.main} !important`,
        },
        '& .ql-fill, .ql-snow .ql-stroke.ql-fill': {
          fill: `${theme.palette.primary.main} !important`,
        },
        '& .ql-stroke': {
          stroke: `${theme.palette.primary.main} !important`,
        },
      },

      '& .ql-picker-label:hover,& .ql-active, & .ql-picker-item:hover': {
        color: `${theme.palette.primary.main} !important`,
        '& .ql-stroke': {
          stroke: `${theme.palette.primary.main} !important`,
        },
      },

      '& .ql-formats': {
        '& .ql-picker': {
          color: theme.palette.text.secondary,
        },
        '& .ql-fill, .ql-snow .ql-stroke.ql-fill': {
          fill: theme.palette.text.secondary,
        },
        '& .ql-stroke': {
          stroke: theme.palette.text.secondary,
        },
        '& .ql-picker-options': {
          background: theme.palette.background.paper,
        },
      },

      '& .ql-editor': {
        minHeight: '18em',
        fontSize: '16px',
      },
    },
  }),
);

const RichEditor = (props: any) => {
  const classes = useStyles();
  const [value, setValue] = useState('');

  const { onChange, onError, data } = props;

  const modules = {
    syntax: true,
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      // [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],
      ['link'], // removed 'image' due to cross-site-scriptiing issue
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageDrop: true,
  };

  const formats = [
    'header',
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'code-block',
  ];

  // const byteSize = (str: string) => new Blob([str]).size;

  // const checkOps = ({ops}: {ops: any;}) => {
  //   let imageSizeCheck = true;
  //   ops.forEach((op: any) => {
  //     if (op?.insert?.image && byteSize(op?.insert?.image) > IMAGE_MAX_SIZE) {
  //       imageSizeCheck = false;
  //     }
  //   });

  //   return imageSizeCheck
  // }

  const handleChange = (html: any, delta: any, source: any, editor: any) => {
    //remove all styling from text to avoid cloudfront block
    const filteredHtml = editor
      .getHTML()
      .replace(/(style=")([a-zA-Z0-9:;\.\s\(\)\-\,]*)(")/gi, '');

    // if (checkOps(delta) && byteSize(editor.getHTML()) < IMAGE_MAX_SIZE) {
    setValue(filteredHtml);
    onChange(filteredHtml);
    // } else {
    //   onError("Image size over 2mb")
    // }
  };

  useEffect(() => {
    setValue(data);
    onChange(data);
  }, [data]);

  useEffect(() => {
    const toolbar: any = document.querySelector('.ql-toolbar');
    const toolbarButtons = [
      ...toolbar?.querySelectorAll('.ql-picker-label'),
      ...toolbar?.getElementsByTagName('button'),
    ];
    toolbarButtons.map((button: any) => {
      button.setAttribute('tabIndex', '-1');
    });
  });

  return (
    <ReactQuill
      theme="snow"
      value={value}
      onChange={handleChange}
      modules={modules}
      //  formats={formats}
      className={classes.quill}
    />
  );
};

export default RichEditor;
