import React, { useContext, useRef } from 'react';
import {
  CardContent,
  Chip,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { timeSince } from '../../utils/dateUtils';
import UserAvatar from '../../common/UserAvatar';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  InformationExchangeApi,
  informationExchangeApiRef,
} from '../../../apis/informationExchange';
import { useApi } from '@backstage/core-plugin-api';
import {
  NotificationApi,
  notificationApiRef,
} from '../../../apis/notificationApi';
import { capitalizeFirstLetter } from '../../home/allAssets/util';
import { userProfileApiRef } from '../../../apis/userProfileApi';
import { IEContext } from '../providers/IEProvider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionCard: {
      display: 'flex',
      marginTop: '15px',
      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
      borderRadius: '4px',
      gap: '24px',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        marginTop: '20px',
      },
    },
    timeStamp: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      gap: '6px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start',
        margin: '15px 0px 15px 0px',
      },
    },
    desc: {
      fontSize: '12px',
      margin: '5px 0px 0px 0px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '14px',
      color: theme.palette.text.tertiary,
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-word',
    },

    title: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      color: theme.palette.primary.main,
      wordBreak: 'break-word',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        fontSize: '17px',
      },
    },
    chip: {
      margin: '10px 0px 0px 0px',
      width: '100%',
    },
    discussion: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      padding: '3px 0px',
      gap: '5px',
    },
    discussionBorder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
      border: `2px solid ${theme.palette.primary.main}`,
      borderColor: '#50AF78',
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      padding: '3px 5px',
      gap: '5px',
    },
    zeroDiscussionBorder: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
      border: `2px solid ${theme.palette.infoText}`,
      color: theme.palette.infoText,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      padding: '3px 5px',
      gap: '5px',
    },

    tag: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    outlineIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#20ab23',
    },

    votes: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      color: theme.palette.text.secondary,
      paddingTop: '3px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8px',
      },
    },
    views: {
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      paddingTop: '8px',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '8px',
      },
    },
    time: {
      color: theme.palette.text.tertiary,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
    },
    leftContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'end',
      width: '150px',
      flexShrink: 0,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        gap: '10px',
        flexDirection: 'row',
        width: 'auto',
        alignItems: 'center',
      },
    },

    delAdminChip: {
      gap: '10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: '8px',
    },
    adminChip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px 5px',
      gap: '8px',
      background: '#FF543E',
      borderRadius: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.005em',
      color: '#FFFFFF',
      margin: '0px',
    },
    answeredChip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '0px 5px',
      gap: '8px',
      background: '#262698',
      borderRadius: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.005em',
      color: '#FFFFFF',
      margin: '0px',
    },
    text: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      display: 'flex',
      alignItems: 'flex-end',
      letterSpacing: '0.005em',
      color: 'rgba(26, 26, 26, 0.6)',
    },
    queDataChips: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    admin: {
      marginRight: '12px',
    },
  }),
);

const QuestionCard = (props: any) => {
  const { questionData, filterSelected, onClick } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const name = questionData?.email && questionData?.email.split('.')[0];

  const usernameRef = useRef(null);
  const userProfileApi = useApi(userProfileApiRef);

  const informationExchange: InformationExchangeApi = useApi(
    informationExchangeApiRef,
  );
  const notificationApi: NotificationApi = useApi(notificationApiRef);

  const convertToPlain = (html: any) => {
    // Create a new div element
    let tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  };

  const deletedByValue = () => {
    return filterSelected === 'myconversations' &&
      questionData.deletedby === 'user'
      ? 'You'
      : capitalizeFirstLetter(questionData.deletedby);
  };

  const navigateToQuestionPage = (tagName: string) => {
    //   if(location?.pathname != '/information-exchange/conversations'){
    //   navigate(`/information-exchange/conversations`, {
    //     state: { tagName: `[${tagName?.trim()}]` },
    //   });
    // }else {
    navigate(`/information-exchange/conversations`, {
      state: { tagName: `[${tagName?.trim()}]` },
    });
    // }
  };

  const onMouseEnter = () => {
    let email: string | undefined = questionData?.email || '';
    userProfileApi.setUserProfileState({
      email,
      anchorRef: usernameRef?.current,
    });
  };

  return (
    <Grid container>
      <CardContent className={classes.questionCard}>
        <Grid item className={classes.leftContent}>
          <span className={classes.votes}>{questionData.votes} votes</span>
          <span>
            {questionData.discussions > 0 &&
              questionData.accepted === false && (
                <span className={classes.discussion}>
                  <div>{questionData.discussions} discussion</div>
                </span>
              )}
            {questionData.discussions > 0 && questionData.accepted === true && (
              <span className={classes.discussionBorder}>
                <div className={classes.outlineIcon}>
                  <Tooltip title="Accepted Answer">
                    <CheckCircleIcon />
                  </Tooltip>
                </div>
                <div>{questionData.discussions} discussion</div>
              </span>
            )}
            {questionData.discussions === 0 && (
              <span className={classes.zeroDiscussionBorder}>
                Be the first to Answer
              </span>
            )}
          </span>
          <span className={classes.views}>{questionData.views} views</span>
        </Grid>
        <Grid item xs={12}>
          {/* Enable once the backend is updated */}
          <div className={classes.queDataChips}>
            {questionData?.answeredbyyou && (
              <div className={`${classes.delAdminChip} ${classes.admin}`}>
                <Chip
                  className={classes.answeredChip}
                  label="Answered by You"
                />
              </div>
            )}
            {questionData?.deletedby && (
              <div className={classes.delAdminChip}>
                <Chip
                  className={classes.adminChip}
                  label={`Deleted by ${deletedByValue()}`}
                />
                <div className={classes.text}>Only visible to you</div>
              </div>
            )}
          </div>
          <div
            className={classes.title}
            {...(!questionData.deletedby && {
              onClick: onClick,
            })}
          >
            {questionData.title}
          </div>
          <Typography className={classes.desc}>
            {convertToPlain(questionData.description)}
          </Typography>
          <div className={classes.tag}>
            <div className={classes.chip}>
              {questionData?.tags &&
                questionData?.tags.map((tag: any) => (
                  <Chip
                    label={tag.slice(0, 15)}
                    key={tag}
                    onClick={() => navigateToQuestionPage(tag)}
                  />
                ))}
            </div>
            <div className={classes.timeStamp}>
              <div>
                {' '}
                <UserAvatar
                  userEmail={questionData.email}
                  customStyles={{
                    width: '32px',
                    height: '32px',
                    fontSize: '12px',
                    fontWeight: 'normal',
                  }}
                />
              </div>
              <div onMouseEnter={onMouseEnter} ref={usernameRef}>
                {name}
              </div>
              <div className={classes.time}>
                {timeSince(questionData.date, 'asked ')}
              </div>
            </div>
          </div>
        </Grid>
      </CardContent>
    </Grid>
  );
};
export default QuestionCard;
