import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { useStyles } from '../styles';
import TextTruncate from 'react-text-truncate';
import { useUtils } from '../hooks/useUtils';
import { IAIAssistantCard } from '../types';

const componentStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: 316,
    minWidth: 240,
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.background.paper}`, // ${theme.palette.grey[300]}`,
    borderRadius: 8,
    color: theme.palette.secondary.main,
    boxShadow: 'none',
  },
  cardHeader: {
    padding: theme.spacing(1.5),
  },
  avatarHeader: {
    marginRight: `${theme.spacing(0.75)}px`,
  },
  avatar: {
    backgroundColor: theme.palette.grey[200],
    width: 32,
    height: 32,
  },
  title: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: 1,
    letterSpacing: '-0.14px',
    color: theme.palette.text.secondary,
  },
  content: {
    fontSize: '14px',
    lineHeight: '20px',
    minHeight: '80px',
  },
  actions: {
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  },
}));

export interface AIAssistantCardProps extends IAIAssistantCard {
  descriptionLines: number;
}

const AIAssistantCard: React.FC<AIAssistantCardProps> = ({
  name,
  iconSrc,
  description,
  descriptionLines,
  learnMoreLink,
  tryNowLink,
}) => {
  const classes = componentStyles();
  const myClasses = useStyles();

  const { navigateTo, copyToClipboard } = useUtils();

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{
          root: myClasses.padding1point5,
          avatar: classes.avatarHeader,
        }}
        avatar={
          <Avatar aria-label="assistant" className={classes.avatar}>
            <div dangerouslySetInnerHTML={{ __html: iconSrc }} />
          </Avatar>
        }
        action={
          <IconButton
            aria-label="share"
            disabled={!tryNowLink}
            onClick={() => copyToClipboard(tryNowLink!)}
          >
            <ShareIcon />
          </IconButton>
        }
        title={<Typography className={classes.title}>{name}</Typography>}
      />
      <CardContent className={classNames(myClasses.padding1point5)}>
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          className={classes.content}
        >
          <TextTruncate line={descriptionLines} text={description} />
        </Typography>
      </CardContent>
      <CardActions
        disableSpacing
        className={classNames(classes.actions, myClasses.padding1point5)}
      >
        {learnMoreLink && (
          <Button
            variant="text"
            size="small"
            className={classNames(
              myClasses.userCase,
              myClasses.fontBold,
              myClasses.textButtonvariant1,
            )}
            // onClick={() => navigateTo(learnMoreLink)}
            href={learnMoreLink}
            target="_blank"
          >
            Learn more
          </Button>
        )}
        {tryNowLink && (
          <Button
            variant="contained"
            size="small"
            className={classNames(
              myClasses.userCase,
              myClasses.buttonvariant4,
              myClasses.fontBold,
            )}
            href={tryNowLink}
            target="_blank"
            //   onClick={() => navigateTo(learnMoreLink)}
          >
            Try now
          </Button>
        )}
        {!tryNowLink && !learnMoreLink && (
          <Button
            disabled
            variant="text"
            size="small"
            color="secondary"
            className={classNames(myClasses.userCase, myClasses.fontBold)}
          >
            Under Development
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default AIAssistantCard;
