import {
  Avatar,
  Link,
  Sidebar,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarSpace,
  SidebarSubmenu,
  SidebarSubmenuItem,
  useSidebarPinState,
} from '@backstage/core-components';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/Home';
import NoteIcon from '@material-ui/icons/Note';
import CodeIcon from './icons/codeIcon';
import ConstructionIcon from '@material-ui/icons/Construction';
import ExtensionIcon from '@material-ui/icons/Extension';
import FeedbackIcon from '@material-ui/icons/Feedback';
import EmraldIcon from './icons/emraldIcon';
import HomeRepairServiceIcon from '@material-ui/icons/HomeRepairService';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HelpOutlineIcon from '@material-ui/icons/HelpOutlined';
import InsightsIcon from '@material-ui/icons/Insights';
import ProvisioningCenterIcon from './icons/provisioningCenterIcon';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
// import CategoryIcon from '@material-ui/icons/CategoryOutlined';
import AppsIcon from '@material-ui/icons/Apps';
import SearchIcon from '@material-ui/icons/Search';
import { SearchModal } from '../search/SearchModal';
import AutoAwesomeIcon from '@material-ui/icons/AutoAwesome';
import { AuthContext } from '../../providers/AuthProvider';
import VisualRegression from './icons/VisualRegression';
import BuildIcon from '@material-ui/icons/Build';
import SidebarItemExpandable from '../common/SidebarItemExpandable';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { sidebarIds } from '../../idSelectors';
import PocIcon from './icons/pocIcon';
import SidebarLogo from './SidebarLogo';
import MenuIcon from '@material-ui/icons/Menu';
import AdminPanelSettingsIcon from '@material-ui/icons/AdminPanelSettings';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import MoreIcon from '@material-ui/icons/More';
import FolderZipIcon from './icons/folderZipIcon';
import SnippetFolderIcon from './icons/snippetFolderIcon';
import GroupIcon from './icons/groupIcon';
import { FrontendConfigContext } from '../../providers/FrontendConfigProvider';

import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import PromptStoreIcon from './icons/promptStoreIcon';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import TechRadar from './icons/TechRadar';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftAvatar: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '10px',
      paddingTop: '10px',
      background: theme.palette.background.default,

      gap: '10px',
      '& div': {
        width: '32px',
        height: '32px',
        fontSize: '14px',
        cursor: 'pointer',
        marginLeft: '20px',
        backgroundColor: theme.palette.background.tertiary,
        color: theme.palette.primary.main,
      },
      '& span': {
        fontSize: '14px',
      },
      '&:hover': {
        '& div': {
          backgroundColor: theme.palette.background.tertiary,
          color: theme.palette.primary.main,
        },
      },
    },
    sidebarItemStyle: {
      marginBottom: '2px',
      '& h6': {
        overflow: 'hidden',
        whiteSpace: 'pre-wrap',
        textOverflow: 'ellipsis',
        marginRight: '10px',
      },
      '& div:nth-of-type(2)': {
        display: 'none',
      },
    },
    sidebarItemWithSubMenu: {
      marginBottom: '2px',
      '& div:nth-of-type(2)': {
        width: '25px',
      },
    },
    buttonSubMenuItem: {
      textTransform: 'none',
      padding: 0,
      width: '100%',
      '& a': {
        backgroundColor: 'transparent',
        color: theme.palette.navigation.color,
      },
    },
    hrDivider: {
      backgroundColor: '#ccc !important',
      opacity: 0.3,
      marginBottom: 0,
    },
    buttonContainer: {
      width: '100%',
      padding: '10px 10px 0px 10px',
      background: theme.palette.background.default,
    },
    mobileButton: {
      minWidth: '0px',
    },
    '@global': {
      '.MuiTypography-h5': {
        color: theme.palette.primary.main,
      },
      'div[data-testid="sidebar-root"] > div': {
        boxShadow: '4px 0px 10px rgba(0, 0, 0, 0.2)',
        borderRight: 'none',
      },
      /*   'div[data-testid*="sidebar-root"] a:hover, div[data-testid*="sidebar-root"] button:hover, a[class*="BackstageSidebarItem-"]:hover button[class*="BackstageSidebarItem-"]:hover':
        {
          background: 'none !important',
        }, */
    },
  }),
);

const AllAssetsGroup = ({ handleYes }: { handleYes: Function }) => {
  const classes = useStyles();
  const [currentPath, setCurrentPath] = useState<string>('/catalog');
  const location = useLocation();

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location.pathname]);

  return (
    <SidebarItem
      icon={FolderZipIcon}
      text="Reusable Code/API"
      className={classes.sidebarItemWithSubMenu}
    >
      <SidebarSubmenu title="Reusable Code/API">
        <SidebarSubmenuItem
          icon={CodeIcon}
          to="code-library"
          title="Components"
          //  className={classes.sidebarItemStyle}
        />
        <SidebarSubmenuItem icon={ExtensionIcon} to="API" title="All APIs" />
        <SidebarSubmenuItem
          icon={AddCircleOutlineIcon}
          to="API/register-api"
          title="Register An Api"
        />
        <Button
          onClick={() => handleYes()}
          className={classes.buttonSubMenuItem}
        >
          <SidebarSubmenuItem
            icon={EmraldIcon}
            title="Storybook"
            to={currentPath}
          />
        </Button>
        <SidebarSubmenuItem
          icon={GroupIcon}
          to="software-templates"
          title="Software Templates"
          //    className={classes.sidebarItemStyle}
        />
        {/* <SidebarSubmenuItem icon={DocIcon} to="docs" title="Docs" />, */}
      </SidebarSubmenu>
    </SidebarItem>
  );
};

/* const MyAssetsGroup = () => {
  return (
    <SidebarItem icon={FolderSharedIcon} text="My Assets">
      <SidebarSubmenu title="My Assets">
        <SidebarSubmenuItem
          icon={SnippetFolderIcon}
          to="catalog/my-assets"
          title="Assets"
          //  className={classes.sidebarItemStyle}
        />
        <SidebarSubmenuItem
          icon={PeopleAltOutlinedIcon}
          to="catalog/subscribe"
          title="Subscribers"
        />
      </SidebarSubmenu>
    </SidebarItem>
  );
}; */

const DeveloperUtilitiesGroup = () => {
  const classes = useStyles();
  return (
    <SidebarItem
      icon={DeveloperBoardIcon}
      text="Dev Tools"
      className={classes.sidebarItemWithSubMenu}
    >
      <SidebarSubmenu title="Dev Tools">
        <SidebarSubmenuItem
          icon={ConstructionIcon}
          to="api-mock"
          title="Mock APIs"
        />
        <SidebarSubmenuItem
          icon={BuildIcon}
          to="utility-tools"
          title="Utility Tools"
        />
        <SidebarSubmenuItem
          icon={VisualRegression}
          to="visual-regression"
          title="Visual Regression"
          //   className={classes.sidebarItemStyle}
        />
        {/* <SidebarSubmenuItem
          icon={TechRadar}
          to="tech-radar"
          title="Tech Radar"
          //   className={classes.sidebarItemStyle}
        /> */}
      </SidebarSubmenu>
    </SidebarItem>
  );
};

const AboutDevxGroup = () => {
  const classes = useStyles();
  return (
    <SidebarItem
      icon={MoreIcon}
      text="About Dev-X"
      className={classes.sidebarItemWithSubMenu}
    >
      <SidebarSubmenu title="About Dev-X">
        <SidebarSubmenuItem
          icon={HelpOutlineIcon}
          to="/help"
          title="User Guide"
        />
        <SidebarSubmenuItem
          icon={FeedbackIcon}
          to="feedback"
          title="Feedback"
        />
        <SidebarSubmenuItem
          icon={NoteIcon}
          to="/release-notes"
          title="Release Notes"
        />
      </SidebarSubmenu>
    </SidebarItem>
  );
};

const AppSidebar = ({
  handleYes,
  isAdminFlag,
  userName,
  profile,
}: {
  handleYes: any;
  isAdminFlag: boolean | null;
  userName: string;
  profile: any;
}) => {
  const classes = useStyles();
  const userContext = useContext(AuthContext);
  const navigate = useNavigate();
  const splitTreatments = useSplitTreatments({
    names: ['devx-ui-feature-enable'],
  });
  const theme = useTheme();
  const themeColor = theme?.palette?.type;
  const { frontendConfigData } = useContext(FrontendConfigContext);

  const navigateToAdmin = () => {
    navigate('admin');
  };

  const { isPinned } = useSidebarPinState();
  const AiInSdlcIcon = () => {
    return <AutoAwesomeIcon fontSize="medium" />;
  };

  const ControlledSideBarItems = [
    {
      name: 'Services & Tools',
      component: (
        <SidebarItem
          icon={HomeRepairServiceIcon}
          to="toolbox"
          text="Services & Tools"
          className={classes.sidebarItemStyle}
          id={sidebarIds.sideNavServicetools}
        />
      ),
    },
    {
      name: 'Information Exchange',
      component: (
        <SidebarItem
          icon={QuestionAnswerIcon}
          to="information-exchange"
          text="Information Exchange"
          className={classes.sidebarItemStyle}
          id={sidebarIds.sideNavInformationExchange}
        />
      ),
    },
    {
      name: 'Data Insights',
      component: (
        <SidebarItem
          icon={InsightsIcon}
          to="data-insights"
          text="Data Insights"
        />
      ),
    },
    {
      name: 'POC Hub',
      component: <SidebarItem icon={PocIcon} to="poc-hub" text="POC Hub" />,
    },
    {
      name: 'Provisioning Center',
      component: (
        <SidebarItem
          icon={ProvisioningCenterIcon}
          to="provisioning-center"
          text="Provisioning Center"
          className={classes.sidebarItemStyle}
        />
      ),
    },

    {
      name: 'Prompt Store',
      component: (
        <SidebarItem
          icon={PromptStoreIcon}
          to="prompt-store"
          text="Prompt Store"
        />
      ),
    },
    {
      name: 'AI in SDLC',
      component: (<SidebarItem icon={AiInSdlcIcon} to="/ai-in-sdlc" text="AI in SDLC" />)
    }
  ];



  const MainMenu = () => {
    return (
      <>
        <SidebarItem icon={HomeIcon} to="/catalog" text="Home" />

        <AllAssetsGroup handleYes={handleYes} />
        {/* <MyAssetsGroup /> */}
        <SidebarItem
          icon={SnippetFolderIcon}
          to="catalog/my-assets"
          text="My Assets"
          className={classes.sidebarItemStyle}
        />
        <DeveloperUtilitiesGroup />
        {ControlledSideBarItems.map((item, idx) => (
          <>
            {(frontendConfigData === undefined ||
              (splitTreatments.factory &&
                splitTreatments?.treatments['devx-ui-feature-enable'].config &&
                JSON.parse(
                  splitTreatments?.treatments['devx-ui-feature-enable'].config,
                ).sidebarFeatures.includes(item.name))) &&
              item.component}
          </>
        ))}
        {/* <SidebarItem
          icon={TrackChangesIcon}
          to="tech-radar"
          text="Tech Radar"
          className={classes.sidebarItemStyle}
        /> */}
        {/* <SidebarItem
          icon={HelpOutlineIcon}
          to="capability-mapping"
          text="Capability Mapping"
          className={classes.sidebarItemStyle}
        /> */}
        <AboutDevxGroup />
        {/* <SidebarItem
          icon={CategoryIcon}
          to="taxonomy"
          text="Capability Taxonomy"
        /> */}
      </>
    );
  };

  const AdminButton = () => {
    return (
      <>
        <div className={classes.buttonContainer}>
          {isPinned ? (
            <Button
              variant="contained"
              color="primary"
              startIcon={<AdminPanelSettingsIcon />}
              onClick={navigateToAdmin}
              fullWidth
            >
              Admin
            </Button>
          ) : (
            <Button
              onClick={navigateToAdmin}
              variant="contained"
              color="primary"
              className={classes.mobileButton}
            >
              <AdminPanelSettingsIcon />
            </Button>
          )}
        </div>
      </>
    );
  };

  return (
    <Sidebar disableExpandOnHover>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal>
          {({ toggleModal }) => <SearchModal toggleModal={toggleModal} />}
        </SidebarSearchModal>
      </SidebarGroup>
      <SidebarDivider className={classes.hrDivider} />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        <MainMenu />
      </SidebarGroup>

      <SidebarSpace />
      <SidebarDivider className={classes.hrDivider} />
      {userContext?.isAdmin || userContext?.isDomainAdmin ? (
        <SidebarGroup
          label="Admin"
          icon={<AdminPanelSettingsIcon />}
          to="/admin"
        >
          <AdminButton />
        </SidebarGroup>
      ) : null}

      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <Link
          to="/settings"
          underline="none"
          component={NavLink}
          className={classes.leftAvatar}
        >
          <Avatar displayName={userName} picture={profile?.picture} />
          {isPinned ? userName : null}
        </Link>
        {/* <SidebarSettings /> */}
      </SidebarGroup>
    </Sidebar>
  );
};
export default AppSidebar;
