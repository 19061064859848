import React, { useContext } from 'react';
import { TabbedLayout } from '@backstage/core-components';
import AllQuestionsPage from './conversations/AllQuestionsPage';
import TagsPage from './tags-page';
import { Route, Routes, useParams } from 'react-router';

const IEHomeTabs = () => {
  return (
    <TabbedLayout>
      <TabbedLayout.Route path="/conversations" title="Conversations">
        <Routes>
          <Route path="/:tagName?" element={<AllQuestionsPage />} />
        </Routes>
      </TabbedLayout.Route>

      <TabbedLayout.Route path="/tags" title="Tags">
        <TagsPage />
      </TabbedLayout.Route>
    </TabbedLayout>
  );
};

export default IEHomeTabs;
