import { createApiRef } from '@backstage/core-plugin-api';
import { AxiosInstance } from 'axios';
import { AxiosInstanceProviderApi } from './axiosInstanceApi';

export interface Tag {
  id: number;
  metadata: string;
  tag: string;
  owner: string;
  conversations: number;
  createdat: string;
  subscribercount: number;
  updatedat: string;
}

export interface TagsResponse {
  count: number;
  tags: Tag[];
}

export interface IAskQuestionItemRequest {
  title: string;
  description: string;
  tags: string[] | undefined;
  category?: string;
}

/* Interface for Edit Question */

export interface IEditQuestionItemRequest {
  id: string | undefined;
  title: string | undefined;
  description: string | undefined;
  tags: string[] | undefined;
}
export interface IEditQuestionItemResponse {
  message: string;
  status: number;
}

/* End for Edit Question */

export interface IHomepageQuestionItem {
  id: string;
  title: string;
  description: string;
  tags?: string[] | undefined;
  email: string;
  date: string;
  votes?: number;
  discussions: number;
  views: number;
}

export interface IAllQuestionItem {
  data: {
    count: number;
    informationexchange: IHomepageQuestionItem[];
  };
}

export interface IHomepageRequest {
  limit?: number;
}

export interface IHomepageResponse {
  data?: IHomepageQuestionItem[];
}

export interface IAllQuestionRequest {
  page?: number | undefined;
  pagesize?: number | undefined;
  filterby?: string | undefined;
  sortby?: string | undefined;
  search?: string | undefined;
}

export interface IAllBookmarkedQuestionRequest extends IAllQuestionRequest {
  idList: string[];
}

/* Interfaces for Question Details */
export interface IEQuestionItem {
  id: string;
  title: string;
  description: string;
  tags?: string[] | undefined;
  author: string;
  createdat: string;
  updatedat: string;
  votes?: number;
  discussions: number;
  views?: number;
  deletedby?: string;
  deletedbyemail?: string;
  reasonfordeletion?: string;
  openaicorrelationid?: string;
}

export interface IEAnswerItem {
  id: string;
  detail: string;
  author: string;
  createdat: string;
  updatedat: string;
  pid: string;
  accepted: boolean;
  vote: number;
  openaicorrelationid?: string;
}

export interface QuestionDetailsResponse {
  question: IEQuestionItem;
  answers: IEAnswerItem[];
}
/* End of Question Details Interface */

/* Interface for User Details */
export interface Vote {
  id: string;
  vote: -1 | 1;
}
export interface UserDetailsResponse {
  id: string;
  vote: -1 | 1;
  answers?: Vote[];
}
/* End of User Details Interface */

interface Prompt {
  role: string;
  content: string;
}

/* Interface for Post Answer */
export interface PostAnswerRequest {
  pid: string | undefined;
  detail: any;
  openaicorrelationid?: string;
}
export interface PostAnswerResponse {
  bot: any;
  openaicorrelationid: any;
}
/* End of Post Answer */

/* Interface for Edit Answer */
export interface EditAnswerRequest {
  id: string;
  detail: string;
}
export interface EditAnswerResponse {
  message: string;
  status: number;
}
/* End of Edit Answer */

/* Interface for Post Vote */
export interface PostVoteRequest {
  id: string;
  vote: 1 | -1;
  actiontype: 'q' | 'a';
}
export interface PostVoteResponse {
  message: string;
}
/* End of Post Vote */

/* Interface for Insert View */
export interface InsertViewResponse {
  status: string;
}
/* Interface for Insert View */

/* Interface for Delete Question */

export interface DeleteQuestionRequest {
  id: string;
  reasonfordelete: string;
  isadmin: boolean | null;
}

export interface DeleteQuestionResponse {
  message: string;
  status: number;
}
/* End of Delete Question */

/* Interface for Delete Answer */
export interface DeleteAnswerRequest {
  id: string;
  reasonfordelete: string;
  isadmin: boolean | null;
}
export interface DeleteAnswerResponse {
  message: string;
  status: number;
}
/* End of Delete Answer */

export interface InformationExchangeApi {
  saveAskAQuestion: (data: IAskQuestionItemRequest) => Promise<any>;
  getAllQuestion: (
    data: IAllQuestionRequest,
  ) => Promise<IAllQuestionItem[] | undefined>;
  getAllBookmarkedQuestions: (
    data: IAllBookmarkedQuestionRequest,
  ) => Promise<IAllQuestionItem[] | undefined>;
  getAllQuestionHomepage: (
    data: IHomepageRequest,
  ) => Promise<IHomepageQuestionItem[] | undefined>;
  getQuestionDetails: (
    qid: String,
    filterBy: String,
  ) => Promise<QuestionDetailsResponse | null>;
  getUserDetails: (qid: String) => Promise<QuestionDetailsResponse | null>;
  postAnswer: (data: PostAnswerRequest) => Promise<PostAnswerResponse | null>;
  updateStatus: (aiId: string, status: string, comment: string) => Promise<any>;
  acceptAnswer: (qid: String, aid: String) => Promise<String>;
  postVote: (data: PostVoteRequest) => Promise<PostVoteResponse | null>;
  getDefaultTags: () => Promise<TagsResponse | null>;
  editAnswer: (data: EditAnswerRequest) => Promise<EditAnswerResponse | null>;
  editQuestion: (
    data: IEditQuestionItemRequest,
  ) => Promise<IEditQuestionItemResponse | null>;
  insertView: (qid: String) => Promise<InsertViewResponse | null>;
  deleteQuestion: (
    data: DeleteQuestionRequest,
  ) => Promise<DeleteQuestionResponse | null>;
  deleteAnswer: (
    data: DeleteAnswerRequest,
  ) => Promise<DeleteAnswerResponse | null>;
  addTags: (data: any) => Promise<any>;
  getAllTags: (data: any) => Promise<any[] | undefined>;
  geByIdTags: (id: any) => Promise<any[] | undefined>;
  deleteTags: (data: any) => Promise<any | null>;
  editTags: (data: any) => Promise<any | null>;
  addSubscribeTag: (data: any) => Promise<any | null>;
  getSubscribeTag: (data: any) => Promise<any | null>;
  getAnswerStream: (payload: any, accessToken: string) => Promise<any | null>;
}

export const informationExchangeApiRef = createApiRef<InformationExchangeApi>({
  id: 'cbre.devx.api.informationexchange',
});

export class InformationExchangeApiImpl implements InformationExchangeApi {
  private readonly axiosInstancePromise: Promise<AxiosInstance>;

  constructor(axiosInstanceProviderApi: AxiosInstanceProviderApi) {
    this.axiosInstancePromise = axiosInstanceProviderApi.getInstance();
  }

  async saveAskAQuestion(data: IAskQuestionItemRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }

    return instance
      .post('service/informationexchange', JSON.stringify(data), {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => {
        //TODO: need to identify all possible request block by WAF
        return res.data;
      });
  }

  async getAllQuestion(
    data: IAllQuestionRequest,
  ): Promise<IAllQuestionItem[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get('service/informationexchange', {
        params: {
          ...data,
        },
      })
      .then(res => res.data);
  }

  async getAllBookmarkedQuestions(
    data: IAllBookmarkedQuestionRequest,
  ): Promise<IAllQuestionItem[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .post('service/informationexchange/bookmarked', {
        params: {
          page: data.page,
          pagesize: data.pagesize,
          sortby: data.sortby,
          filterby: data.filterby,
          search: data.search,
        },
        data: {
          idList: data?.idList,
        },
      })
      .then(res => res.data);
  }

  async getAllQuestionHomepage(
    data: IHomepageRequest,
  ): Promise<IHomepageQuestionItem[] | undefined> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/informationexchange/homepage', {
        params: {
          limit: data.limit || 5,
        },
      })
      .then(res => res.data);
  }

  /* Get Question Details */
  async getQuestionDetails(qid: String, filterBy: String) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/informationexchange/${qid}`, {
        params: {
          filterby: filterBy,
        },
      })
      .then(res => res.data);
  }

  /* Get User Details */
  async getUserDetails(qid: String) {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/informationexchange/userdata/${qid}`)
      .then(res => res.data);
  }

  /* Post Answer */
  async postAnswer(data: PostAnswerRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }
    const endpoint = 'prompt' in data ? 'openai/chat' : 'answer';
    return instance
      .request({
        method: 'post',
        url: `service/informationexchange/${endpoint}`,
        data: { ...data },
        // headers: {
        //   'Content-Type': 'multipart/form-data',
        // },
      })
      .then(res => res.data);
  }

  async updateStatus(aiId: string, status: string, comment: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance
      .put(`service/informationexchange/openai/chat/status`, {
        openaicorrelationid: aiId,
        status,
        comment
      })
      .then(res => res.data);
  };

  /* Accept Answer */
  async acceptAnswer(qid: String, aid: String): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .put(`service/informationexchange/${qid}/answer/${aid}`)
      .then(res => res.data);
  }

  /* Post Vote */
  async postVote(data: PostVoteRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;

    return instance
      .request({
        method: 'post',
        url: `service/informationexchange/vote`,
        data: { ...data },
      })
      .then(res => res.data);
  }

  async getDefaultTags(): Promise<TagsResponse | null> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/informationexchange/tags')
      .then(res => res.data);
  }

  /* Edit Answer */
  async editAnswer(data: EditAnswerRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }
    return instance
      .request({
        method: 'put',
        url: `service/informationexchange/answer`,
        data: { ...data },
      })
      .then(res => res.data);
  }

  /* Edit Question */
  async editQuestion(data: IEditQuestionItemRequest): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject('Invalid Payload');
    }

    return instance
      .put('service/informationexchange', JSON.stringify(data), {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(res => res.data);
  }

  /* Post insert view */
  async insertView(qid: String) {
    const instance = await this.axiosInstancePromise;
    return instance
      .post(`service/informationexchange/view/${qid}`)
      .then(res => res.data);
  }

  /* Delete Question */
  async deleteQuestion(data: DeleteQuestionRequest) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`/service/informationexchange/${data.id}`, {
        data: {
          reasonfordelete: data.reasonfordelete,
          isadmin: data.isadmin,
        },
      })
      .then(res => res.data);
  }

  /* Delete Answer */
  async deleteAnswer(data: DeleteAnswerRequest) {
    const instance = await this.axiosInstancePromise;
    return instance
      .delete(`/service/informationexchange/answer/${data.id}`, {
        data: {
          reasonfordelete: data.reasonfordelete,
          isadmin: data.isadmin,
        },
      })
      .then(res => res.data);
  }
  async addTags(payload: any): Promise<AxiosResponse> {
    const instance = await this.axiosInstancePromise;
    return instance.post(`service/informationexchange/tags`, {
      ...payload,
    });
  }

  async getAllTags(data: any): Promise<any[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get("service/informationexchange/tags", {
        params: {
          page: data.page,
          pagesize: data.pagesize,
          sortby: data.sortby,
          search: data.search,
        },
      })
      .then((res) => res.data);
  }

  async geByIdTags(id: any): Promise<any[] | undefined> {
    const instance = await this.axiosInstancePromise;
    return instance
      .get(`service/informationexchange/tags/${id}`)
      .then((res) => res.data);
  }

  async editTags(data: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    if (!data) {
      return Promise.reject("Invalid Payload");
    }
    return instance.put(`service/informationexchange/tags/${data.id}`, {
      ...data,
    });
  }

  async deleteTags(id: DeleteAnswerRequest) {
    const instance = await this.axiosInstancePromise;
    return instance.delete(`/service/informationexchange/tags/${id}`);
  }

  async getSubscribeTag(data:string): Promise<TagsResponse | null> {
    const instance = await this.axiosInstancePromise;

    return instance
      .get('service/informationexchange/tags/subscribe', {
        params: {
          username:data
        },})
      .then(res => res.data);
  }


  async addSubscribeTag(payload: any): Promise<any> {
    const instance = await this.axiosInstancePromise;
    return instance.post('service/informationexchange/tags/subscribe', {
      ...payload,
    });
  }
  // axios XHR can not handle data stream use browser fetch instead
  async getAnswerStream(payload: any, accessToken: string): Promise<any> {
    const instance = await this.axiosInstancePromise;
    const response = await fetch(
      `${instance.getUri()}service/informationexchange/openai/chat/stream`,
      {
        method: 'POST',
        headers: {
          'api-version': '1.0',
          'Content-Type': 'text/event-stream',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload),
      },
    );

    return response;
  }
}
