import React from 'react';
import { useStyles } from '../styles';
import {
  Box,
  Typography,
  Button,
  Avatar,
  IconButton,
  Tooltip,
} from '@material-ui/core';

interface AiServicesSmallProps {
  text: string;
  aiServices: any;
  buttonText?: string;
}

const AiServicesSmall = ({
  text,
  aiServices,
  buttonText,
}: AiServicesSmallProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.aiServicesWrapper}>
      {/* <Typography variant="body2" className={classes.aiServicesText}>
        {text}
      </Typography> */}
      <Box className={classes.aiServiceIcons}>
        {aiServices?.map((service: any, index: number) => (
          <Tooltip
            title={service.name}
            key={service.name}
            classes={{ tooltip: classes.toolTip }}
          >
            <div>
              <IconButton
                key={index}
                disabled
                className={classes.iconWrapper}
                onClick={event => {
                  event.stopPropagation();
                  window.open(service.tryNowLink, '_blank');
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: service.iconSrc }} />
              </IconButton>
            </div>
          </Tooltip>
        ))}
        {buttonText ? (
          <Button className={classes.moreButton}>{buttonText}</Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default AiServicesSmall;
