import React, { useContext, useEffect, useState } from 'react';
import { PageWithHeader } from '@backstage/core-components';
import { DevxBreadCrumb } from '../common/BreadcrumbsNav/DevxBreadCrumb';
import { Route, Routes, useLocation } from 'react-router';
import QuestionDetailsPage from './question-details-page';
import AskQuestionPage from './AskQuestionPage';
import IEHomeTabs from './IEHomeTabs';
import { IEContext } from './providers/IEProvider';
import { Content } from '@backstage/core-components';
import AllQuestionsPage from './conversations/AllQuestionsPage';

const IEHome = () => {
  const location = useLocation();
  const myIEContext = useContext(IEContext);

  const initialBreadcrumValue: any = {
    title: 'Information Exchange',
    subTitleRoutes: [
      {
        type: 'link',
        text: 'Home',
        link: '/',
      },
      {
        type: 'link',
        text: 'Information-exchange',
        link: '/information-exchange',
      },
    ],
  };

  const [breadCrumb, setBreadCrumb] = useState(initialBreadcrumValue);

  const ieBreadCrumbs = () => {
    const askQuestion = location.pathname.includes('/ask-question');
    const editQuestion = location.pathname.includes('/edit-question');
    const postDetails = location.pathname.includes('/question');

    let title = initialBreadcrumValue.title;
    let subTitleRoutes = initialBreadcrumValue.subTitleRoutes;
    let objToInsert = null;
    if (askQuestion) {
      objToInsert = {
        type: 'text',
        text: 'Ask-question',
        link: '/information-exchange/ask-question',
      };
    }
    if (editQuestion) {
      objToInsert = {
        type: 'text',
        text: 'Edit-question',
      };
    }
    if (postDetails) {
      title = 'Post Details';
      objToInsert = {
        type: 'text',
        text: 'Post-details',
      };
    }
    if (objToInsert !== null)
      subTitleRoutes = [...initialBreadcrumValue.subTitleRoutes, objToInsert];
    else {
      subTitleRoutes[1].type = 'text';
    }

    setBreadCrumb({
      title,
      subTitleRoutes,
    });
  };

  const updateShowAiAnswer = () => {
    if (
      location.pathname === '/information-exchange' ||
      location.pathname === '/information-exchange/conversations'
    ) {
      myIEContext.setShowAiAnswer(false);
    }
  };

  useEffect(() => {
    ieBreadCrumbs();
    updateShowAiAnswer();
  }, [location]);

  return (
    <PageWithHeader
      themeId="home"
      pageTitleOverride={breadCrumb?.title}
      subtitle={<DevxBreadCrumb routes={breadCrumb?.subTitleRoutes} />}
      title={undefined}
    >
      <Routes>
        <Route path="/*" element={<IEHomeTabs />} />

        {/* For Tag based conversations */}
        <Route
          path="/:tagName/conversations/"
          element={
            <Content>
              <AllQuestionsPage />
            </Content>
          }
        />

        <Route
          path="/:tagName?/question/:questionId"
          element={<QuestionDetailsPage />}
        />
        <Route path="/:tagName?/ask-question/" element={<AskQuestionPage />} />
        <Route
          path="/:tagName?/edit-question/:questionId"
          element={<AskQuestionPage />}
        />
      </Routes>
    </PageWithHeader>
  );
};

export default IEHome;
