/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  TablePagination,
  Paper,
  LinearProgress,
  Typography,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
  ListSubheader,
  Theme,
} from '@material-ui/core';
import ClearButton from '@material-ui/icons/Clear';
import { WarningPanel, CodeSnippet } from '@backstage/core-components';
import { HelpComponent } from '../../utils/helpComponent';
import HELP_URL from '../../utils/helpLinkConstant';
import BackButton from '../../utils/backButton';
import { useNavigate, useParams } from 'react-router-dom';
import InputBase from '@material-ui/core/InputBase';
import QuestionCard from './QuestionCard';
import SearchIcon from '@material-ui/icons/Search';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import FilterAltIcon from '@material-ui/icons/FilterAlt';
import { AuthContext } from '../../../providers/AuthProvider';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { IEContext } from '../providers/IEProvider';
import { useGetQuestions } from '../hooks/useGetQuestions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    allQuestions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'row',
      padding: '0px !important',
      gap: '20px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'normal',
      },
    },
    questionsCodePage: {
      [theme.breakpoints.down('sm')]: {
        padding: '0',
        marginTop: '20px',
      },
    },
    root: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '10px',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        marginRight: '0px',
      },
    },
    card: {
      display: 'flex',
      alignItems: 'center',
      '& h3': {
        marginLeft: '10px',
      },
    },
    queCard: {
      display: 'flex',
    },
    timeStamp: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    btnDefault: {
      backgroundColor: 'transparent',
      color: theme.palette.text.secondary,
      textTransform: 'none',
      padding: '6px 10px',
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    btnSelected: {
      padding: '6px 10px',
      backgroundColor: '#CFCFCF',
      color: '#00000094',
      textTransform: 'none',
      '&:hover': {
        backgroundColor: '#CFCFCF',
      },
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    questionsCount: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '20px',
      color: theme.palette.text.secondary,
      lineHeight: '24px',
    },
    queHeading: {
      display: 'flex',
      alignItems: 'center',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '24px',
      color: theme.palette.text.secondary,
      lineHeight: '32px',
    },
    askQueBtn: {
      display: 'flex',
      alignItems: 'center',
      padding: '12px 16px',
      justifyContent: 'center',
      gap: '8px',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '4px',
      textTransform: 'initial',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    search: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    dropdown: {
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    countSection: {
      marginTop: '10px',
    },
    btnTabs: {
      // border: '1px solid #538184',
      borderRadius: '4px',

      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
    PagePaginationContainer: {
      width: '100%',
      marginTop: '15px',
      marginBottom: '110px',
    },
    tablePaginationRoot: {
      overflow: 'hidden',
    },
    tablePaginationActions: {
      '& button': {
        [theme.breakpoints.down('sm')]: {
          padding: '0',
        },
      },
    },
    swapIcon: {
      background: '#538184',
      color: 'white',
      cursor: 'unset',
      '&:hover': {
        background: '#538184',
        color: 'white',
      },
      '&.Mui-disabled': {
        color: 'white',
      },
      [theme.breakpoints.down('sm')]: {
        flex: '1',
      },
    },
    filterTabs: {
      marginLeft: '10px',
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        marginTop: '10px',
        justifyContent: 'center',
        // alignItems: 'center',
        display: 'flex',
        marginLeft: '0px',
      },
    },
    filterPopup: {
      zIndex: 11,
      boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
    },
    filterMenuOption: {
      lineHeight: '1.5',
      color: theme.palette.text.secondary,
    },
    typographyNoQuestion: {
      fontWeight: 'bold',
      textAlign: 'center',
      width: '100 %',
      margin: '32px 0px',
    },
  }),
);

const debounce = (fn: any, delay: any) => {
  let timeoutId: any;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), delay);
  };
};

const AllQuestionsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const { tagName } = useParams();

  const [filters, setFilters] = useState<any>({
    selectedBtn: 'newest',
    filterSelected: 'all',
    searchTextValue: '',
    currentIndex: 0,
  });

  const [contentPerPage, setContentPerPage] = useState(20);
  const [searchText, setSearchText] = useState<string | undefined>('');
  const { profEmail, isAdmin } = useContext(AuthContext);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  /* clear IEContext */
  const { setAcceptedAnswer, setCachedQuestion } = useContext(IEContext);
  useEffect(() => {
    setAcceptedAnswer(null);
    setCachedQuestion(null);
  }, []);
  /* clear IEContext ends */

  const FILTER_OPTIONS = [
    {
      label: 'All',
      value: 'all',
    },
    {
      label: 'Unanswered',
      value: 'unanswered',
    },
    {
      label: 'My Conversations',
      value: 'myconversations',
    },
    {
      label: 'Bookmarked',
      value: 'bookmarked',
    },
  ];

  if (isAdmin) {
    FILTER_OPTIONS.push({
      label: '',
      value: 'divider',
    });
    FILTER_OPTIONS.push({
      label: 'Admin',
      value: 'subheader',
    });
    FILTER_OPTIONS.push({
      label: 'Deleted Conversations',
      value: 'deleted',
    });
  }

  const handleMenuItemClick = (event: any, index: number) => {
    setOpen(false);

    const selectedOption = FILTER_OPTIONS[index];
    if (selectedOption && selectedOption?.value) {
      setSearchText('');
      updateFilters({
        filterSelected: selectedOption.value,
        searchTextValue: '',
        currentIndex: 0,
      });
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = (event: any) => {
    // @ts-expect-error
    if (anchorRef.current && anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const navigateToAskQuestionPage = () => {
    tagName
      ? navigate(`/information-exchange/${tagName}/ask-question`)
      : navigate('/information-exchange/ask-question');
  };

  const { questionsData, isLoading } = useGetQuestions(
    contentPerPage,
    tagName,
    filters,
  ); // eslint-disable-line

  const updateFilters = (data: any) => {
    setFilters((prevState: any) => {
      return { ...prevState, ...data };
    });
  };

  const onPageChange = (
    event: React.SyntheticEvent | null,
    pageNumber: number,
  ): void => {
    if (event) {
      event.preventDefault();
    }
    updateFilters({ currentIndex: pageNumber });
  };

  const onRowsPerPageChange = (event: React.SyntheticEvent | null): void => {
    if (event) {
      event.preventDefault();
    }
    setContentPerPage(event?.target?.value);
  };

  const handleClearSearch = () => {
    setSearchText('');
    updateFilters({ searchTextValue: '' });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceCallback = useCallback(
    debounce((value: any) => {
      //   setCurrentIndex(0);
      updateFilters({
        searchTextValue: value,
        filterSelected: 'all',
        currentIndex: 0,
      });
    }, 1000),
    [],
  );

  const onChangeSearchText = async (e: any) => {
    setSearchText(e?.target?.value);
    debounceCallback(e.target.value);
  };

  const { count, informationexchange } = questionsData || { count: 0 };

  const navigateToQueDetails = (questionData: any) => {
    setCachedQuestion({
      ...questionData,
      createdat: questionData?.date,
      updatedat: questionData?.date,
      author: questionData?.email,
    });
    tagName
      ? navigate(`/information-exchange/${tagName}/question/${questionData.id}`)
      : navigate(`/information-exchange/question/${questionData.id}`);
  };

  return (
    <>
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <BackButton />
          <HelpComponent helpUrl={HELP_URL.INFO_EXCHANGE_ALL_QUESTION_PAGE} />
        </Grid>
      </Grid>
      {!isLoading && !questionsData && (
        <div>
          <WarningPanel severity="error" title="Could not fetch data">
            <CodeSnippet language="text" text="Error" />
          </WarningPanel>
        </div>
      )}
      <div className={`codePage ${classes.questionsCodePage}`}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.allQuestions}
          >
            <div className={classes.queHeading}>All Conversations</div>
            <div>
              <Button
                className={classes.askQueBtn}
                onClick={navigateToAskQuestionPage}
                variant="contained"
                color="primary"
              >
                Post your question
              </Button>
            </div>
          </Grid>
          <Grid container className={classes.countSection} alignItems="center">
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div className={classes.questionsCount}>
                {count} Conversations
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={10}
              className={classes.search}
            >
              <Paper component="form" className={classes.root}>
                <IconButton disabled type="submit" aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  placeholder="Search "
                  fullWidth
                  inputProps={{ 'aria-label': 'search ' }}
                  value={searchText}
                  onChange={e => onChangeSearchText(e)}
                />
                <IconButton
                  aria-label="clear search"
                  onClick={() => handleClearSearch()}
                >
                  <ClearButton />
                </IconButton>
              </Paper>

              <ButtonGroup
                disableElevation
                size="large"
                variant="outlined"
                className={classes.btnTabs}
              >
                <Button disabled className={classes.swapIcon}>
                  <SwapVertIcon />
                </Button>
                <Button
                  className={
                    filters.selectedBtn === 'newest'
                      ? classes.btnSelected
                      : classes.btnDefault
                  }
                  onClick={() =>
                    updateFilters({ selectedBtn: 'newest', currentIndex: 0 })
                  }
                >
                  Newest
                </Button>
                <Button
                  className={
                    filters.selectedBtn === 'active'
                      ? classes.btnSelected
                      : classes.btnDefault
                  }
                  onClick={() =>
                    updateFilters({ selectedBtn: 'active', currentIndex: 0 })
                  }
                >
                  Active
                </Button>
              </ButtonGroup>
              <ButtonGroup
                disableElevation
                disableRipple
                disableFocusRipple
                size="large"
                variant="outlined"
                className={classes.filterTabs}
                ref={anchorRef}
              >
                <Button disabled className={classes.swapIcon}>
                  <FilterAltIcon />
                </Button>
                <Button
                  className={classes.dropdown}
                  aria-controls={open ? 'select-filter-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-label="Select a filter"
                  aria-haspopup="menu"
                  onClick={handleToggle}
                  endIcon={<ExpandMore />}
                >
                  {filters.filterSelected
                    ? filters.filterSelected === 'myconversations'
                      ? 'my conversations'
                      : filters.filterSelected
                    : 'All'}
                </Button>
              </ButtonGroup>
              <Popper
                className={classes.filterPopup}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList id="select-filter-menu">
                          {FILTER_OPTIONS.map((option, index) => (
                            <div key={option?.value}>
                              <MenuItem
                                key={option?.value}
                                disabled={
                                  option.value === 'divider' ||
                                  option?.value === 'subheader'
                                }
                                selected={
                                  filters.filterSelected === option.value
                                }
                                onClick={event =>
                                  handleMenuItemClick(event, index)
                                }
                              >
                                <Typography
                                  variant="body1"
                                  className={classes.filterMenuOption}
                                >
                                  {option.value === 'divider'
                                    ? ''
                                    : option?.value === 'subheader'
                                    ? ''
                                    : option.label}
                                </Typography>
                              </MenuItem>
                              {option.value === 'divider' && <Divider />}
                              {option.value === 'subheader' && (
                                <ListSubheader style={{ userSelect: 'none' }}>
                                  {option.label}
                                </ListSubheader>
                              )}
                            </div>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Grid>
          </Grid>
          {!informationexchange && (
            <Typography className={classes.typographyNoQuestion}>
              No Questions Found
            </Typography>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {isLoading && <LinearProgress />}
            {informationexchange &&
              informationexchange?.map((questionData: any) => (
                <QuestionCard
                  filterSelected={filters.filterSelected}
                  questionData={questionData}
                  key={questionData.id}
                  onClick={() => navigateToQueDetails(questionData)}
                ></QuestionCard>
              ))}
          </Grid>
          {informationexchange &&
            informationexchange !== undefined &&
            informationexchange?.length > 0 && (
              <div className={classes.PagePaginationContainer}>
                <Paper>
                  <TablePagination
                    classes={{
                      root: classes.tablePaginationRoot,
                      actions: classes.tablePaginationActions,
                    }}
                    SelectProps={{
                      title: 'perPageSelect',
                      native: true,
                    }}
                    component="div"
                    page={filters.currentIndex}
                    rowsPerPage={contentPerPage}
                    count={count}
                    onPageChange={onPageChange}
                    onRowsPerPageChange={onRowsPerPageChange}
                    rowsPerPageOptions={[5, 10, 15, 20]}
                  />
                </Paper>
              </div>
            )}
        </Grid>
      </div>
    </>
  );
};
export default AllQuestionsPage;
